<template>
	<div class="main-content">
		<div class="meunTop">
			<div class="meunLine"></div>
			<div class="meunTitle">手动分账</div>
		</div>
		<div class="flex">
			<div>
				<el-form class="search" label-position='left' ref="seachForm" :model="seachForm" size="large">
                    <el-form-item style="margin-left:20px;display:flex;" label="支付时间：">
                        <el-date-picker
                        v-model="dateBetween"
                        @change="selecttime"
                        type="datetimerange"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        :default-time="['00:00:00']">
                        </el-date-picker>
					</el-form-item>
                    <el-form-item style="margin-left:20px;">
						<el-input  :maxlength="50" style="width:300px;" v-model="seachForm.searchInfo" placeholder="请输入支付交易号/订单编号" clearable></el-input>
					</el-form-item>
					<el-form-item style="margin-left:20px;">
						<el-button  type="primary" @click="seachSumbit(seachForm)">搜索</el-button>
					</el-form-item>
				</el-form>
			</div>
		</div>
		<div class="table-content">
			<el-table :cell-style="{ textAlign: 'center' }" :header-cell-style="{textAlign: 'center',backgroundColor:'#F5F7FA',color:'#333'}" :border="true" :data="data" style="width: 100%">
				<el-table-column prop="orderNo" label="订单编号" ></el-table-column>
				<el-table-column prop="orderSerialNo" label="支付交易号" ></el-table-column>
				<el-table-column prop="nickName" label="支付人" ></el-table-column>
				<el-table-column prop="phone" label="手机号" ></el-table-column>
				<el-table-column prop="payTime" label="支付时间" ></el-table-column>
				<el-table-column prop="supplierName" label="供应商名称" ></el-table-column>
				<el-table-column prop="dinddjf" label="应结算积分" ></el-table-column>
				<el-table-column prop="dinddjf" label="未结算积分" ></el-table-column>
				<el-table-column prop="platformYingjsjf" label="平台应结算积分" ></el-table-column>
				<el-table-column prop="supplierYingjsjf" width="180" label="供应商应结算积分" ></el-table-column>
				<el-table-column prop="resultTime" label="结算日期" ></el-table-column>
				<el-table-column prop="orderResultStatus" label="结算状态">
                    <template slot-scope="scope">
                        <span >{{scope.row.orderResultStatus == 0 ? '未结算' : '已结算'}}</span>
                    </template>
                </el-table-column>
				<el-table-column fixed="right" label="操作">
					<template style="display: flex;" slot-scope="scope">
						<el-button @click.native.prevent="updataclick(scope.$index, data)" :disabled="scope.row.orderResultStatus == 1" type="text" size="small">
							分账
						</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>

		<!-- 新增的弹窗 -->
		<el-dialog :visible.sync="dialogVisible" width="700px" :before-close="handleClose" title="分账">
			<el-form class="form_box" :model="sizeForm" label-position='right'  ref="sizeForm"  size="small">
				<el-form-item>
                    <template slot-scope="scope">
						<div style="display:flex;" :src="scope.row">
							<p style="min-width:150px;text-align:right;margin-right:10px;">支付时间：</p>
							<span>{{sizeForm.payTime}}</span>
						</div>
					</template>
				</el-form-item>
				<el-form-item>
                    <template slot-scope="scope">
						<div style="display:flex;" :src="scope.row">
							<p style="min-width:150px;text-align:right;margin-right:10px;">未结算积分：</p>
							<span>{{sizeForm.dinddjf}}</span>
						</div>
					</template>
				</el-form-item>
               	<el-form-item>
                    <template slot-scope="scope">
						<div style="display:flex;" :src="scope.row">
							<p style="min-width:150px;text-align:right;margin-right:10px;">供应商名称：</p>
							<span>{{sizeForm.supplierName}}</span>
						</div>
					</template>
				</el-form-item>
				<el-form-item>
                    <template slot-scope="scope">
						<div style="display:flex;" :src="scope.row">
							<p style="min-width:150px;text-align:right;margin-right:10px;"><span style="color:red">*</span>平台应结算积分：</p>
							<el-input class="numberInput" type="number" width="200px" v-model="sizeForm.supplierYingjsjf"></el-input>
						</div>
					</template>
				</el-form-item>
				<el-form-item>
                    <template slot-scope="scope">
						<div style="display:flex;" :src="scope.row">
							<p style="min-width:150px;text-align:right;margin-right:10px;"><span style="color:red">*</span>供应商应结算积分：</p>
							<el-input class="numberInput" type="number" width="200px" v-model="sizeForm.platformYingjsjf"></el-input>
						</div>
					</template>
				</el-form-item>
				<el-form-item size="large" style="display:flex;justify-content:flex-end;">
                    <el-button @click.native.prevent="handleClose('sizeForm')">取消</el-button>
					<el-button type="primary"  @click.native.prevent="submitForm('sizeForm')">确定</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>

		<div class="">
			<el-pagination @current-change="handleCurrentChange" :current-page.sync="currentPage"
				:page-count="pageCount" :page-size="pageSize" layout="total, prev, pager, next" :total="total">
			</el-pagination>
		</div>
	</div>
</template>

<script>
	import {
		supplierOrderSplit,supplierOrderList
	} from "@/api/platform/index";
	export default {
		name: "manual",
		data() {
			return {
                seachForm:{
					payTime: '',
					searchInfo: '',
				},
                dateBetween:'',
				dialogVisible: false,
				data: [],
				sizeForm: {
                    id: '',
                    platformYingjsjf: '',
                    supplierYingjsjf: '',
                    dinddjf: '',
                    supplierName: '',
                    payTime:'',
				},
				page: 1,
				pageSize: 10,
				total: 0, //总数据
				currentPage: 0, //当前页页数
				pageCount: 0, //总页数
				addtype: '',
				id: 0,
			};
		},
		mounted() {
			let that = this;
			that.list();
		},
		created() {
		},
		methods: {
			//获取列表
			list() {
				supplierOrderList({
                    payTime: this.seachForm.payTime,
                    searchInfo:this.seachForm.searchInfo,
					page: this.page,
					size: this.pageSize,
				}).then(res => {
					this.data = res.data.records;
					this.total = res.data.total;
					this.currentPage = res.data.current;
					this.pageCount = res.data.pages;
					if (res.data.current > res.data.pages && res.data.pages != 0) {
						this.page = res.data.pages;
						this.list();
					}
				})
			},

            // 筛选开始结束日期
            selecttime(){
                if(this.dateBetween === null){
                    this.seachForm.payTime = '';
                    return
                }
                let startime = this.dateBetween[0];
                let y = startime.getFullYear();
                let m = startime.getMonth() + 1;
                let r = startime.getDate();
                let h = startime.getHours();
                let mm = startime.getMinutes();
                let s = startime.getSeconds();
                m < 10 ? m = '0' + m : ''
                r < 10 ? r = '0' + r : ''
                h < 10 ? h = '0' + h : ''
                mm < 10 ? mm = '0' + mm : ''
                s < 10 ? s = '0' + s : ''
                let datetime = y + '-' + m + '-' + r +' '+ h + ":" + mm + ":" + s;
                let endtime = this.dateBetween[1];
                let y1 = endtime.getFullYear();
                let m1 = endtime.getMonth() + 1;
                let r1 = endtime.getDate();
                let h1 = endtime.getHours();
                let mm1 = endtime.getMinutes();
                let s1 = endtime.getSeconds();
                m1 < 10 ? m1 = '0' + m1 : ''
                r1 < 10 ? r1 = '0' + r1 : ''
                h1 < 10 ? h1 = '0' + h1 : ''
                mm1 < 10 ? mm1 = '0' + mm1 : ''
                s1 < 10 ? s1 = '0' + s1 : ''
                let datetime1 = y1 + '-' + m1 + '-' + r1 +' '+ h1 + ":" + mm1 + ":" + s1;
                this.seachForm.payTime = datetime + ' ~ ' + datetime1;
            },
            
            //搜索提交
			seachSumbit(formName){
				this.page = 1
				this.list();
			},

			//分页
			handleCurrentChange(val) {
				this.page = val;
				this.list();
			},

			//编辑提交
			submitForm(formName){
				let that = this;
				if(that.dialogVisible){
					that.$refs[formName].validate((valid) => {
						if (valid) {
                            if(that.$refs[formName].model.platformYingjsjf.toString().length > 0 && Number(that.$refs[formName].model.platformYingjsjf) >= 0 ){
								if (that.$refs[formName].model.supplierYingjsjf.toString().length > 0 && Number(that.$refs[formName].model.supplierYingjsjf) >= 0 ){
									supplierOrderSplit({
										id: that.$refs[formName].model.id,
										platformYingjsjf: that.$refs[formName].model.platformYingjsjf,
										supplierYingjsjf: that.$refs[formName].model.supplierYingjsjf,
									}).then(res=>{
										if (res.code == 200) {
											that.$message.success('修改成功');
											that.sizeForm = {
												id: '',
												platformYingjsjf: '',
												supplierYingjsjf: '',
												dinddjf: '',
												supplierName: '',
												payTime:'',
											}
											that.dialogVisible = false
											that.page = 1
											that.list();
										} else {
											that.$message.error(res.msg);
										}
									}).catch(err => {
										that.$message.error(err.msg);
									})
								}else{
                             	   that.$message.warning('平台应结算积分不能为空且不能为负数');
								}
                            }else{
                                that.$message.warning('供应商应结算积分不能为空且不能为负数');
                            }
						} else {
							return false;
						}
					});
				}
			},
			
			//关闭提醒
			handleClose(done) {
				this.sizeForm = {
					id: '',
					platformYingjsjf: '',
					supplierYingjsjf: '',
					dinddjf: '',
					supplierName: '',
					payTime:'',
				}
				this.dialogVisible = false
			},

			//编辑按钮点击
			updataclick(i,tab){
                console.log(tab[i]);
				this.sizeForm = {
                    id: tab[i].id,
                    platformYingjsjf: tab[i].platformYingjsjf,
                    supplierYingjsjf: tab[i].supplierYingjsjf,
                    dinddjf: tab[i].dinddjf,
                    supplierName: tab[i].supplierName,
                    payTime:tab[i].payTime,
				}
				this.id = tab[i].id
				this.dialogVisible = true
			},
		}
	}
</script>

<style scoped>
	.table-content {margin-top: 20px;}
	.active {color: #049EC4;}
	.form_box{padding:0 50px;}
	.avatar{width:150px;}
	.photo{width:150px;height:150px;border:1px solid #eee;display:flex;align-items: center;justify-content:center;font-size:40px;color:#ccc;}
	.flex{display:flex;align-items:center;justify-content:space-between;}

	.meunTop{display:flex;align-items:center;margin-bottom:20px;}
	.meunLine{width:3px;height:20px;background-color:#FFA218;}
	.meunTitle{font-size:20px;margin-left:10px;}
    .el-input__inner{
        -webkit-appearance: none !important;
        -moz-appearance: textfield !important;
    }
    .search .el-form-item{
        margin-bottom: 0;
    }
</style>
